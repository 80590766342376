<template>
  <div class="q-pa-md">
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <div class="text-h6">Database Sync</div>
        <div class="text-subtitle2">
          <div class="row">
            <div class="col">
              <q-select
                outlined
                v-model="selecteddb"
                emit-value
                map-options
                :options="dbs"
                option-value="couchdb_name"
                label="Database Selection"
                option-label="db_name"
                stack-label
                @update:model-value="compare(selecteddb)"
              >
              </q-select>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated } from "vue";
import { useQuasar, LocalStorage } from "quasar";
import Store from "@/store/index";
export default {
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Settings`;
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onUpdated(() => {});
    onMounted(() => {
      fetchdbs();
    });
    const $q = useQuasar();
    let dbs = ref([]);
    let selecteddb = ref("");
    // fetch dbs
    async function fetchdbs() {
      const res = await Store.dispatch('getdbs').catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      })
      dbs.value = res
    }
    async function compare() {
      const res = await Store.dispatch("comparedbs", {
        db: selecteddb.value
      }).catch((err) => {
        console.log(err)
      });
      console.log(res)
    }
    return {
      fetchdbs,
      compare,
      dbs,
      selecteddb,
    };
  },
};
</script>
