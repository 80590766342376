<template>
  <div class="q-pa-md">
    <q-table
      title="API Logs"
      :rows="logs"
      :columns="columns"
      row-key="name"
      :pagination="{ descending: true, sortBy: 'timestamp', rowsPerPage: 50 }"
    >
      <template v-slot:body="props">
        <q-tr style="text-align: left" :props="props">
          <q-td style="text-align: center" key="system" :props="props">
            {{ props.rowIndex + 1 }}
          </q-td>
          <q-td key="timestamp" :props="props">
            {{ props.row.timestamp }}
          </q-td>
          <q-td key="system" :props="props">
            {{ props.row.system }}
          </q-td>
          <q-td key="status" :props="props">
            {{ props.row.status }}
          </q-td>
          <q-td key="request" :props="props">
            {{ props.row.request }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated } from "vue";
import { useQuasar, LocalStorage } from "quasar";
import Store from "@/store/index";
export default {
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Settings`;
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onUpdated(() => {});
    onMounted(() => {
      getlogs();
    });
    const $q = useQuasar();
    let logs = ref([]);
    const columns = [
      {
        name: "system",
        label: "#",
        align: "left",
        field: "system",
        sortable: true,
      },
      {
        name: "timestamp",
        label: "Timestamp",
        align: "left",
        field: "timestamp",
        sortable: true,
      },
      {
        name: "system",
        label: "System IP",
        align: "left",
        field: "system",
        sortable: true,
      },
      {
        name: "status",
        label: "Status Code",
        align: "left",
        field: "status",
        sortable: true,
      },
      {
        name: "request",
        label: "Request URL",
        align: "left",
        field: "request",
        sortable: true,
      },
    ];
    async function getlogs() {
      logs.value = await Store.dispatch("getlogs").catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "g",
          position: "bottom-left",
        });
      });
    }
    return {
      getlogs,
      logs,
      columns,
    };
  },
};
</script>
