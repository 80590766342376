<template>
  <div class="q-pa-md">
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <div class="text-h6">Database Sync</div>
      </q-card-section>
      <q-card-actions>
        <q-btn
          color="primary"
          label="Fetch Updates & Additions"
          @click="fetchComparisonData"
        />
      </q-card-actions>
    </q-card>
  </div>

  <div class="q-pa-md" v-for="(dbData, dbName) in dbSyncData" :key="dbName">
    <!-- Additions Table -->
    <q-table
      :title="`Additions for ${dbName}`"
      :rows="dbData.additions"
      :columns="addcolumns"
      row-key="name"
      selection="multiple"
      v-model:selected="dbData.selectedAdditions"
      flat
      bordered
    />

    <!-- Updates Table -->
    <q-table
      :title="`Updates for ${dbName}`"
      :rows="dbData.updates"
      :columns="updatecolumns"
      row-key="name"
      selection="multiple"
      v-model:selected="dbData.selectedUpdates"
      flat
      bordered
    >
      <template v-slot:body="props">
        <!-- Table Row with Default Selection Checkbox -->
        <q-tr :props="props">
          <q-td>
            <!-- Use built-in checkbox for row selection -->
            <q-checkbox v-model="props.selected" />
          </q-td>
          <!-- Render columns dynamically -->
          <q-td v-for="col in updatecolumns" :key="col.name" :props="props">
            {{ props.row[col.field] }}
          </q-td>
        </q-tr>

        <!-- Expanded Row with Details -->
        <q-tr :props="props" class="q-pt-none">
          <q-td colspan="100%">
            <q-expansion-item expand-separator label="View Details" dense>
              <div class="q-gutter-md row">
                <div class="col">
                  <div class="text-bold text-primary">Stock Data</div>
                  <q-markup-table v-if="props.row.stockdocs">
                    <tr
                      v-for="(stockValue, lang) in props.row.stockdocs"
                      :key="lang"
                    >
                      <td>{{ lang.toUpperCase() }}</td>
                      <td>
                        <table>
                          <tr v-for="(value, key) in stockValue" :key="key">
                            <td>
                              <strong
                                >{{
                                  key.replace("_", " ").toUpperCase()
                                }}:</strong
                              >
                            </td>
                            <td v-html="value"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </q-markup-table>
                </div>

                <div class="col">
                  <div class="text-bold text-primary">Child Data</div>
                  <q-markup-table v-if="props.row.docs">
                    <tr
                      v-for="(childValue, lang) in props.row.docs"
                      :key="lang"
                    >
                      <td>{{ lang.toUpperCase() }}</td>
                      <td>
                        <table>
                          <tr v-for="(value, key) in childValue" :key="key">
                            <td>
                              <strong
                                >{{
                                  key.replace("_", " ").toUpperCase()
                                }}:</strong
                              >
                            </td>
                            <td v-html="value"></td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </q-markup-table>
                </div>
              </div>
            </q-expansion-item>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>

  <!-- Sync Selected Button -->
  <div class="q-pa-md">
    <q-btn color="primary" label="Sync Selected" @click="syncSelected" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useQuasar } from "quasar";
import Store from "@/store/index";

export default {
  setup() {
    const $q = useQuasar();
    const dbSyncData = ref({});
    const contentMatchFilter = ref("All"); // Filter for Content Match column

    const updatecolumns = [
      {
        name: "Name",
        label: "Document Name",
        align: "left",
        field: "name",
        sortable: true,
      },
      {
        name: "parentDocRev",
        label: "Parent Revision",
        align: "left",
        field: "parentDocRev",
        sortable: true,
      },
      {
        name: "childDocRev",
        label: "Child Revision",
        align: "left",
        field: "childDocRev",
        sortable: true,
      },
      {
        name: "match",
        label: "Content Match",
        field: "match",
        align: "center",
        sortable: true,
        filter: (value) => {
          // Apply filter based on contentMatchFilter value
          if (contentMatchFilter.value === "All") return true;
          return String(value) === contentMatchFilter.value;
        },
      },
    ];

    const addcolumns = [
      {
        name: "Name",
        label: "Document Name",
        align: "left",
        field: "name",
        sortable: true,
      },
      {
        name: "parentDocRev",
        label: "Parent Revision",
        align: "left",
        field: "parentDocRev",
        sortable: true,
      },
    ];

    async function fetchComparisonData() {
      try {
        const res = await Store.dispatch("comparestockdbs");
        for (const [dbName, data] of Object.entries(res)) {
          dbSyncData.value[dbName] = {
            additions: data.additions,
            updates: data.updates,
            selectedAdditions: [],
            selectedUpdates: [],
          };
        }
      } catch (err) {
        $q.notify({ message: `Error fetching data: ${err}`, color: "red" });
      }
    }

    async function syncSelected() {
      const syncPayload = {};

      for (const [dbName, data] of Object.entries(dbSyncData.value)) {
        syncPayload[dbName] = {
          additions: data.selectedAdditions,
          updates: data.selectedUpdates,
        };
      }

      try {
        console.log(syncPayload);
        await Store.dispatch("syncstockdbs", syncPayload);
        $q.notify({ message: "Sync successful", color: "green" });
        //fetchComparisonData();
      } catch (err) {
        $q.notify({ message: `Sync error: ${err}`, color: "red" });
      }
    }

    return {
      fetchComparisonData,
      syncSelected,
      dbSyncData,
      updatecolumns,
      addcolumns,
      contentMatchFilter,
    };
  },
};
</script>
