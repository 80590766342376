<template>
  <div class="q-pa-md">
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <div class="text-h6">Branding Configuration</div>
        <div class="text-subtitle2">
          <div class="row">
            <div class="col">
              <q-select
                outlined
                v-model="selecteddb"
                emit-value
                map-options
                :options="dbs"
                option-value="id"
                label="Database Selection"
                option-label="name"
                stack-label
                @update:model-value="fetchbranding(selecteddb)"
              >
              </q-select>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </q-card-section>

      <q-markup-table>
        <thead>
          <tr>
            <th class="text-left">Section</th>
            <th class="text-left">Values</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">Product Name</td>
            <td class="text-left">
              <q-chip
                v-for="content in branding.product_name"
                :key="content"
                removable
                @remove="removebranding(content, 'product_name')"
              >
                {{ content }}
              </q-chip>
            </td>
            <td class="text-right">
              <q-btn
                size="14px"
                hint="test"
                class="no-padding"
                color="primary"
                icon-right="add_circle"
                no-caps
                flat
                dense
              >
                <q-menu
                  behavior="menu"
                  v-model="addprompt[0]"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-input
                    borderless
                    hide-bottom-space
                    autofocus
                    bottom-slots
                    v-model="addtext"
                    @keydown.enter="addbrandoption('product_name')"
                    class="no-padding"
                  >
                    <template v-slot:append>
                      <q-btn
                        round
                        dense
                        flat
                        icon="add"
                        @click="addbrandoption('product_name')"
                      />
                    </template>
                  </q-input>
                </q-menu>
              </q-btn>
            </td>
          </tr>
          <tr>
            <td class="text-left">Client App Name</td>
            <td class="text-left">
              <q-chip
                v-for="content in branding.clientapp_name"
                :key="content"
                removable
                @remove="removebranding(content, 'clientapp_name')"
              >
                {{ content }}
              </q-chip>
            </td>
            <td class="text-right">
              <q-btn
                size="14px"
                hint="test"
                class="no-padding"
                color="primary"
                icon-right="add_circle"
                no-caps
                flat
                dense
              >
                <q-menu
                  behavior="menu"
                  v-model="addprompt[1]"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-input
                    borderless
                    hide-bottom-space
                    autofocus
                    bottom-slots
                    v-model="addtext"
                    @keydown.enter="addbrandoption('clientapp_name')"
                    class="no-padding"
                  >
                    <template v-slot:append>
                      <q-btn
                        round
                        dense
                        flat
                        icon="add"
                        @click="addbrandoption('clientapp_name')"
                      />
                    </template>
                  </q-input>
                </q-menu>
              </q-btn>
            </td>
          </tr>
          <tr>
            <td class="text-left">Company Name</td>
            <td class="text-left">
              <q-chip
                v-for="content in branding.company_name"
                :key="content"
                removable
                @remove="removebranding(content, 'company_name')"
              >
                {{ content }}
              </q-chip>
            </td>
            <td class="text-right">
              <q-btn
                size="14px"
                hint="test"
                class="no-padding"
                color="primary"
                icon-right="add_circle"
                no-caps
                flat
                dense
              >
                <q-menu
                  behavior="menu"
                  v-model="addprompt[2]"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-input
                    borderless
                    hide-bottom-space
                    autofocus
                    bottom-slots
                    v-model="addtext"
                    @keydown.enter="addbrandoption('company_name')"
                    class="no-padding"
                  >
                    <template v-slot:append>
                      <q-btn
                        round
                        dense
                        flat
                        icon="add"
                        @click="addbrandoption('company_name')"
                      />
                    </template>
                  </q-input>
                </q-menu>
              </q-btn>
            </td>
          </tr>
          <tr>
            <td class="text-left"></td>
            <td class="text-left"></td>
            <td class="text-right">
              <q-btn label="Save" color="green" @click="savebranding()" />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated } from "vue";
import { useQuasar, LocalStorage } from "quasar";
import Store from "@/store/index";
export default {
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Settings`;
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onUpdated(() => {});
    onMounted(() => {
      fetchdbs();
    });
    const $q = useQuasar();
    let dbs = ref([]);
    let branding = ref({});
    let selecteddb = ref("");
    let addprompt = ref([false, false, false]);
    let addtext = ref("");
    // fetch dbs
    async function fetchdbs() {
      const res = await Store.dispatch("getdbs").catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "red",
          position: "bottom-left",
        });
      });
      let dbarr = await res.filter(function (res) {
        return res[0] != "_";
      });
      dbarr.forEach((element) => {
        let value = element.replaceAll("_", " ");
        value = value.replaceAll("pbxware mt", "PBXware MT");
        value = value.replaceAll("pbxware cc", "PBXware CC");
        value = value.replaceAll("pbxware biz", "PBXware BIZ");
        value = value.replaceAll("serverware", "SERVERware");
        const arr = value.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        dbs.value.push({ id: element, name: arr.join(" ") });
      });
    }
    async function fetchbranding(data) {
      branding.value = await Store.dispatch("getbranding", data)
        .then((data) => {
          return data.branding;
        })
        .catch((err) => {
          $q.notify({
            message: `${err}`,
            color: "red",
            position: "bottom-left",
          });
        });
    }
    function addbrandoption(data) {
      if (addtext.value != "") {
        if (branding.value[data].find((x) => x === addtext.value)) {
          $q.notify({
            message: "Value already exists.",
            color: "green",
            position: "bottom-left",
          });
        } else {
          branding.value[data].push(addtext.value);
          addprompt.value = [false, false, false];
          addtext.value = "";
        }
      }
    }
    function removebranding(data, field) {
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to remove this value?",
        cancel: true,
        persistent: false,
      }).onOk(() => {
        branding.value[field].splice(
          branding.value[field].findIndex((item) => item === data),
          1
        );
      });
    }
    async function savebranding() {
      await Store.dispatch("putbranding", {
        db: selecteddb.value,
        branding: branding.value,
      })
        .then((res) => {
          console.error(res);
          fetchbranding(selecteddb.value);
          $q.notify({
            message: `Branding Updated.`,
            color: "green",
            position: "bottom-left",
          });
        })
        .catch((err) => {
          console.error(err);
          $q.notify({
            message: `Error: ${err.response.data.message} Error code: ${err.response.status}`,
            color: "red",
            position: "bottom-left",
          });
        });
    }
    return {
      fetchdbs,
      fetchbranding,
      dbs,
      branding,
      selecteddb,
      addbrandoption,
      addprompt,
      addtext,
      removebranding,
      savebranding,
    };
  },
};
</script>
