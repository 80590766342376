import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import quasarUserOptions from './quasar-user-options'
import axios from 'axios'

let token = LocalStorage.getItem('token');
if( token ){
 axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

createApp(App)
.use(Quasar, {
    plugins: {
      Notify,
      Dialog,
    },
    config: {
      notify: {}
    }
  }, quasarUserOptions)
  .use(router)
  .mount('#app')