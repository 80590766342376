<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-toolbar-title> Embedded Documentation Manager </q-toolbar-title>
        <q-space />
      </q-toolbar>
    </q-header>
    <q-page-container>
      <div class="q-pa-md">
        <div class="q-gutter-lg row justify-center">
          <q-img
            src="@/assets/bicom_logo.png"
            width="250px"
            class="q-gutter-md"
          />
        </div>
        <div class="q-pa-md">
          <div class="q-gutter-lg row justify-center">
            <q-form @submit="onSubmit" class="q-gutter-md">
              <q-input
                filled
                v-model="username"
                label="Email"
                lazy-rules
                :rules="[(val) => !!val || 'Please enter email.', isValidEmail]"
              />
              <q-input
                filled
                type="password"
                v-model="password"
                label="Password"
                lazy-rules
                :rules="[
                  (val) => (val && val.length > 0) || 'Please enter password',
                ]"
              />
              <div
                style="padding-top: 20px"
                class="q-gutter-lg row justify-center"
              >
                <q-btn size="md" label="Login" type="submit" color="primary" />
              </div>
            </q-form>
          </div>
        </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import router from "@/router";
import { onMounted, ref } from "vue";
import { useQuasar } from "quasar";
import Store from "@/store/index";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Login`;
    await Store.dispatch("checklogin")
      .then(() => {
        next({
          path: "/",
        });
      })
      .catch(() => {
        next();
      });
  },
  setup() {
    onMounted(() => {});
    const route = useRoute();
    const username = ref(null);
    const password = ref(null);
    const $q = useQuasar();
    return {
      username,
      password,
      isValidEmail(data) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(data) || "Invalid email";
      },
      async onSubmit() {
        const data = {};
        data.email = username.value;
        data.password = password.value;
        const res = await Store.dispatch("submitlogin", data).catch((err) => {
          $q.notify({
            message: `Login Failed. ${err}`,
            color: "red",
            position: "bottom-left",
          });
        });
        localStorage.setItem("token", res.accces_token);
        localStorage.setItem("role", res.role);
        localStorage.setItem("email", res.email);
        localStorage.setItem("hosted", res.hosted);
        if (res.accces_token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.accces_token;
        }
        if (res.status == 201) {
          $q.notify({
            message: "Login Successful",
            color: "green",
            position: "bottom-left",
          });
          if (route.query.from) {
            router.replace(route.query.from);
          } else {
            router.replace(`/`);
          }
        }
      },
    };
  },
};
</script>
