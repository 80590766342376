<template>
  <div class="q-pa-md">
    <q-table
      hide-bottom
      :rows-per-page-options="[]"
      :pagination="{ rowsPerPage: 0 }"
      :rows="users"
      row-key="id"
    >
      <template v-slot:header>
        <q-tr style="text-align: left">
          <q-th style="text-align: center">#</q-th>
          <q-th>Account Email</q-th>
          <q-th>Role</q-th>
          <q-th>Created At</q-th>
          <q-th>Updated At</q-th>
          <q-th style="text-align: right"
            ><q-btn
              size="12px"
              color="blue"
              stack
              label="Add User"
              icon="person_add"
              no-caps
              flat
              dense
              @click="adduserprompt = !adduserprompt"
          /></q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr style="text-align: left" :props="props">
          <q-td style="text-align: center" key="id" :props="props">
            {{ props.rowIndex + 1 }}
          </q-td>
          <q-td key="email" :props="props">
            {{ props.row.email }}
          </q-td>
          <q-td key="role" :props="props">
            {{
              props.row.role.charAt(0).toUpperCase() + props.row.role.slice(1)
            }}
          </q-td>
          <q-td key="created" :props="props">
            {{
              date.formatDate(
                date.extractDate(props.row.created),
                "h:mm A on Do MMM YYYY"
              )
            }}
          </q-td>
          <q-td key="updated" :props="props">
            {{
              date.formatDate(
                date.extractDate(props.row.updated),
                "h:mm A on Do MMM YYYY"
              )
            }}
          </q-td>
          <q-td style="" key="action">
            <div class="row justify-center">
              <div class="col"></div>
              <div class="col-auto">
                <q-btn
                  size="12px"
                  color="blue"
                  stack
                  label="Update"
                  icon="edit"
                  no-caps
                  flat
                  dense
                  @click="updateuser(props.row)"
                />
                <q-btn
                  size="12px"
                  color="negative"
                  stack
                  label="Delete"
                  icon="delete"
                  no-caps
                  flat
                  dense
                  @click="deleteuser(props.row)"
                />
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
  <q-dialog v-model="adduserprompt">
    <q-card style="min-width: 350px">
      <div class="q-pa-md">
        <div class="q-gutter-lg row justify-center">
          <q-form @submit="createuser" class="q-gutter-md">
            <q-input
              filled
              v-model="createuseremail"
              label="Email"
              lazy-rules
              :rules="[(val) => !!val || 'Please enter email.', isValidEmail]"
            />
            <q-input
              filled
              type="password"
              v-model="createuserpass"
              label="Password"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please enter password',
              ]"
            />
            <q-select
              v-model="createuserrole"
              :options="roles"
              :option-value="'id'"
              :option-label="'name'"
              label="Role"
            />
            <div
              style="padding-top: 20px"
              class="q-gutter-lg row justify-center"
            >
              <q-btn label="Create User" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </div>
    </q-card>
  </q-dialog>
  <q-dialog v-model="updateuserprompt">
    <q-card style="min-width: 350px">
      <div class="q-pa-md">
        <div class="q-gutter-lg row justify-center">
          <q-form @submit="updateuser" class="q-gutter-md">
            <q-input
              filled
              v-model="createuseremail"
              label="Email"
              lazy-rules
              :rules="[(val) => !!val || 'Please enter email.', isValidEmail]"
            />
            <q-input
              filled
              type="password"
              v-model="createuserpass"
              label="Password"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Please enter password',
              ]"
            />
            <q-select
              v-model="createuserrole"
              :options="roles"
              :option-value="'id'"
              :option-label="'name'"
              label="Role"
            />
            <div
              style="padding-top: 20px"
              class="q-gutter-lg row justify-center"
            >
              <q-btn label="Create User" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, onUpdated } from "vue";
import { useQuasar, LocalStorage, date } from "quasar";
import Store from "@/store/index";
export default {
  async beforeRouteEnter(to, from, next) {
    document.title = `EmDoc - Settings`;
    await Store.dispatch("checklogin")
      .then(() => {
        next();
      })
      .catch(() => {
        LocalStorage.remove("token");
        LocalStorage.remove("role");
        const loginpath = window.location.pathname + location.search;
        next({
          path: "/login",
          query: { from: loginpath },
        });
      });
  },
  setup() {
    onUpdated(() => {});
    onMounted(() => {
      getusers();
    });
    const $q = useQuasar();
    let users = ref([]);
    let roles = ref([]);
    let adduserprompt = ref(false);
    const createuseremail = ref(null);
    const createuserpass = ref(null);
    const createuserrole = ref(null);
    async function getusers() {
      users.value = await Store.dispatch("getusers").catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "g",
          position: "bottom-left",
        });
      });
      roles.value = await Store.dispatch("getroles").catch((err) => {
        $q.notify({
          message: `${err}`,
          color: "g",
          position: "bottom-left",
        });
      });
    }
    function isValidEmail(data) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(data) || "Invalid email";
    }
    async function createuser() {
      const data = {
        email: createuseremail.value,
        password: createuserpass.value,
        role: createuserrole.value.id,
      };
      await Store.dispatch("createuser", data).catch((err) => {
        $q.notify({
          message: `User creation failed. ${err}`,
          color: "red",
          position: "bottom-left",
        });
      });
      adduserprompt.value = false;
      createuseremail.value = null;
      createuserpass.value = null;
      createuserrole.value = null;
      getusers();
    }
    function deleteuser(data) {
      $q.dialog({
        title: "Confirm",
        message: "Are you sure you want to delete this user?",
        cancel: true,
        persistent: false,
      }).onOk(() => {
        Store.dispatch("deleteuser", data.id)
          .then((res) => {
            console.error(res);
            $q.notify({
              message: `User deleted.`,
              color: "green",
              position: "bottom-left",
            });
            getusers();
          })
          .catch((err) => {
            console.error(err);
            $q.notify({
              message: `${err}`,
              color: "red",
              position: "bottom-left",
            });
          });
      });
    }
    return {
      getusers,
      users,
      roles,
      date,
      adduserprompt,
      createuseremail,
      createuserpass,
      createuserrole,
      isValidEmail,
      createuser,
      deleteuser,
    };
  },
};
</script>
